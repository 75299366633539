import "./App.css";
import SmoothScroll from "smooth-scroll";
import { Home } from "./components/home";
import { About } from "./components/about/about";
import { LayoutTop } from "./components/layoutTop";
import { LayoutBottom } from "./components/layoutBottom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ServiceView } from "./components/serviceView/serviceView";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <BrowserRouter>
      <LayoutTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/services/architecture-consulting/"
          element={<ServiceView serviceName="architectureConsulting" />}
        />
        <Route
          path="/services/data-analytics/"
          element={<ServiceView serviceName="dataAnalytics" />}
        />
        <Route
          path="/services/data-engineering/"
          element={<ServiceView serviceName="dataEngineering" />}
        />
        <Route
          path="/services/cloud-native-app-development/"
          element={<ServiceView serviceName="cloudNativeAppDevelopment" />}
        />
        <Route
          path="/services/devsecops/"
          element={<ServiceView serviceName="devSecOps" />}
        />
        <Route
          path="/services/cost-engineering/"
          element={<ServiceView serviceName="costEngineering" />}
        />
        <Route path="/about-us/" element={<About />} />
        {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} /> */}
        <Route path="*" element={<Home />} />
      </Routes>
      <LayoutBottom />
    </BrowserRouter>
  );
};

export default App;
