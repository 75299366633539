import Typewriter from "typewriter-effect";

export interface HeaderDataFormat {
  titles: string[];
  paragraph: string;
}

export interface HeaderProps {
  data?: HeaderDataFormat;
}

//Old: accelerate your digital transformation

export const Header = (props: HeaderProps) => {
  const titles = props.data?.titles || [""];

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  Accelerate Your
                  <Typewriter
                    options={{
                      strings: titles,
                      autoStart: true,
                      loop: true,
                    }}
                  />
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Let's talk
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
