import Link from "@mui/material/Link";

export interface ServiceItem {
  icon: string;
  name: string;
  text: string;
  path: string;
}

export interface ServiceProps {
  data?: ServiceItem[];
}

export const Services = (props: ServiceProps) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            We offer customized solutions for small and medium-sized businesses,
            from architecture design to big data implementation, to help your
            business thrive
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <Link href={d.path} underline="none">
                      <h3>{d.name}</h3>
                    </Link>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
