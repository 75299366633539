import { useState } from "react";
import emailjs from "@emailjs/browser";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";

export interface ContactDataFormat {
  address: string;
  phone: string;
  email: string;
  linkedin: string;
  twitter: string;
  medium: string;
}

export interface ContactProps {
  data: ContactDataFormat | undefined;
}

interface ContactState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  message: string;
  openSnackbar: boolean;
  snackBarSeverity: AlertColor | undefined;
  snackbarMsg: string;
}

const initialState: ContactState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyName: "",
  message: "",
  openSnackbar: false,
  snackBarSeverity: undefined,
  snackbarMsg: "",
};

export const Contact = (props: ContactProps) => {
  const [
    {
      firstName,
      lastName,
      email,
      phone,
      companyName,
      message,
      openSnackbar,
      snackBarSeverity,
      snackbarMsg,
    },
    setState,
  ] = useState(initialState);
  //close snackbar when requested
  const handleSnackbarClose = () => {
    clearState();
    console.log("Close snackbar.");
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  /**
   * Send email to hello@manateelabsusa.com
   * @param e
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(firstName, lastName, email, phone, companyName, message);
    emailjs
      .sendForm(
        "service_aq8i1yc",
        "template_jzsk33s",
        e.target,
        "Bm2fPDfgw3mNfita-"
      )
      .then(
        (result) => {
          console.log(result.text);
          setState({
            firstName,
            lastName,
            email,
            phone,
            companyName,
            message,
            openSnackbar: true,
            snackbarMsg: "Message sent successfully",
            snackBarSeverity: "success",
          });
        },
        (error) => {
          console.log(error.text);
          setState({
            firstName,
            lastName,
            email,
            phone,
            companyName,
            message,
            openSnackbar: true,
            snackbarMsg:
              "Sorry! There was a problem. Please try again or reach out directly to hello@manateelabsusa.com",
            snackBarSeverity: "error",
          });
        }
      );
  };

  /**
   * Validate that the contact form is ready to be submitted
   * @returns boolean
   */
  const isFormValid = (): boolean => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phone !== "" &&
      companyName !== "" &&
      message !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contact Us</h2>
                <p>
                  Please fill out the form below and we will get back to you as
                  soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                {/* First and last name */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                {/* Email and phone */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                {/* Comapany Name */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        className="form-control"
                        placeholder="Company Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                {/* Message */}
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows={4}
                    placeholder="What can we help you with today?"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg"
                  disabled={!isFormValid()}
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              {/* <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p> */}
            </div>
            {/* TODO: Create Amazon Connect instance and put phone number here.. */}
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div> */}
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          {/* TODO: Add social media back when I create it. */}
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.linkedin : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.medium : "/"}>
                      <i className="fa fa-medium"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
        message={snackbarMsg}
        key={"top" + "center"}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};
