/**
 * Defines the home page.
 */
import { Value } from "./value";
import { Team, TeamItem } from "./team";
import JsonData from "../data/data.json";
import { useState, useEffect } from "react";
import { Gallery, GalleryItem } from "./gallery";
import { Services, ServiceItem } from "./services";
import { Header, HeaderDataFormat } from "./header";
import { Testimonials, Testimonial } from "./testimonials";
import { CertificatesView } from "./certificates/certificatesView";

interface LandingPageDataFormat {
  Header?: HeaderDataFormat;
  Gallery?: GalleryItem[];
  Services?: ServiceItem[];
  Testimonials?: Testimonial[];
  Team?: TeamItem[];
}

export const Home = () => {
  const [landingPageData, setLandingPageData] = useState<LandingPageDataFormat>(
    {}
  );
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <Value />
      <Services data={landingPageData.Services} />
      <CertificatesView />
      {/* <Gallery data={landingPageData.Gallery}/>
          <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
    </div>
  );
};
