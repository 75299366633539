/**
 * Defines the MUI Card used in the Service View to showcase examples of what we can do.
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import CardContent from "@mui/material/CardContent";

export interface ServiceViewCardProps {
  itemName: string;
  itemTasks: string[];
  itemSuccessDrivers: string[] | undefined;
}

export const ServiceViewCard = (props: ServiceViewCardProps) => {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined" className="item-card">
        <React.Fragment>
          <CardContent>
            {/* Header */}
            <Typography variant="h3" component="div">
              {" "}
              {props.itemName}
            </Typography>
            {/* Task List */}
            <Typography variant="h6" color="text.secondary">
              <ul>
                {props.itemTasks.map((d, i) => (
                  <div key={`${d}-${i}`} className="card-item-bullet-point">
                    <li>
                      {" "}
                      <CheckIcon></CheckIcon>
                      {d}
                    </li>
                  </div>
                ))}
              </ul>
            </Typography>
          </CardContent>
        </React.Fragment>
      </Card>
    </Box>
  );
};
