/**
 * Creates the Mission view. Used on the about us page.
 */
export interface MissionValue {
  icon: string;
  title: string;
  text: string;
}

export interface MissionProps {
  data?: MissionValue[];
}

export const Mission = (props: MissionProps) => {
  return (
    <div id="mission" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Our Mission</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h4 className="misson-value-header">{d.title}</h4>
                  <h4>{d.text}</h4>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
