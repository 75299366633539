/**
 * Defines the page to showcase an individual service.
 */
import { useState, useEffect } from "react";
import { ServiceViewCard } from "./serviceViewCard";
import ServicesJsonData from "../../data/servicesData.json";

export interface serviceItem {
  itemName: string;
  itemTasks: string[];
  itemSuccessDrivers?: string[];
}

export interface ServiceData {
  keyBenefits?: string[];
  header?: string;
  subheader?: string;
  serviceItems?: serviceItem[];
}

export interface ServicesData {
  architectureConsulting?: ServiceData;
  dataAnalytics?: ServiceData;
  dataEngineering?: ServiceData;
  cloudNativeAppDevelopment?: ServiceData;
  devSecOps?: ServiceData;
  costEngineering?: ServiceData;
}

export interface ServiceViewProps {
  //name of service to match with serviceData.json
  serviceName: string;
}

export const ServiceView = (props: ServiceViewProps) => {
  const [servicePageData, setServicePageData] = useState<ServiceData>({});
  useEffect(() => {
    switch (props.serviceName) {
      case "architectureConsulting": {
        setServicePageData(ServicesJsonData.architectureConsulting);
        break;
      }
      case "dataAnalytics": {
        setServicePageData(ServicesJsonData.dataAnalytics);
        break;
      }
      case "dataEngineering": {
        setServicePageData(ServicesJsonData.dataEngineering);
        break;
      }
      case "cloudNativeAppDevelopment": {
        setServicePageData(ServicesJsonData.cloudNativeAppDevelopment);
        break;
      }
      case "devSecOps": {
        setServicePageData(ServicesJsonData.devSecOps);
        break;
      }
      case "costEngineering": {
        setServicePageData(ServicesJsonData.costEngineering);
        break;
      }
      default: {
        //TODO: Need to handle errors better.
        const errMsg = `Service name ${props.serviceName} is not available.`;
        throw new Error(errMsg);
      }
    }
  }, [props]);
  return (
    <div>
      <div id="serviceBenefitsView" className="text-center">
        {/* Service view benefits */}
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2> {servicePageData.header} </h2>
            <p>{servicePageData.subheader}</p>
          </div>
          <div className="row">
            {servicePageData.keyBenefits
              ? servicePageData.keyBenefits.map((d, i) => (
                  <div key={`${d}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className="fa fa-check"></i>
                    <h4> {d}</h4>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      {/* Cards highlighting key services offerred */}
      <div id="serviceItemView">
        <div className="container">
          <div className="row">
            {servicePageData.serviceItems
              ? servicePageData.serviceItems.map((d, i) => (
                  <div key={`${d.itemName}-${i}`} className="col-md-4">
                    <ServiceViewCard
                      itemName={d.itemName}
                      itemTasks={d.itemTasks}
                      itemSuccessDrivers={d.itemSuccessDrivers}
                    ></ServiceViewCard>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
