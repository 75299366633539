/**
 * Defines the Certificates View that shows my AWS certificates.
 */
import { useEffect } from "react";
export const CertificatesView = () => {
  const certificateIds = [
    "06f84888-bbd5-434a-8982-cce185a4963e",
    "7bb2665b-958f-4a0c-93dd-d82c7eb478e2",
    "6d40cf43-17a9-49e5-a893-2c7c902d53c7",
    "ba5c2dec-94e9-491e-a1e2-961d1c4f3ffa",
    "724f2d5a-b1f4-45de-96d7-abd38378a21c",
    "639b2ed6-f799-40ba-b033-3608383c2c5e",
  ];

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdn.credly.com/assets/utilities/embed.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="certificates" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>We Are Certified</h2>
        </div>
        <div className="row">
          {/* certificates */}
          {certificateIds.map((d, i) => (
            <div key={`${d}-${i}`} className="col-md-4">
              <div
                className="service-desc"
                data-iframe-width="300"
                data-iframe-height="300"
                data-share-badge-id={`${d}`}
                data-share-badge-host="https://www.credly.com"
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
