import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ReactComponent as ComputerSVG } from "../logo.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        color: "#555",
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          fontSize: "large",
          fontFamily: ["Open Sans", "sans-serif"],
          color: "#555",
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        sx: { marginRight: 2 },
        elevation: 0,
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandMoreIcon />,
        "aria-controls": "panel1a-content",
        sx: {
          fontSize: "large",
          fontFamily: ["Open Sans", "sans-serif"],
          color: "#555",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          color: "#555",
          fontSize: "14px",
          padding: "15px",
          textTransform: "none",
        },
      },
    },
  },
});

export const Navigation = () => {
  /**
   * Handles Mobile drawer
   */
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  /**
   * Handle drop down Services menu for desktop screens
   */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ mt: 2 }}>
        <Link href="/">
          <ComputerSVG className="navbar-brand-div" />
        </Link>
      </Typography>
      <List>
        <ListItem key="Services" disablePadding>
          <Accordion>
            <AccordionSummary id="panel1a-header">Services</AccordionSummary>
            <AccordionDetails>
              <Typography variant="h6">
                {/* Services list */}
                <List>
                  <ListItem
                    key="architectureConsultingListItem"
                    disablePadding
                    sx={{ marginBottom: 2 }}
                  >
                    <Link href="/services/architecture-consulting/">
                      {" "}
                      Architecture Consulting
                    </Link>
                  </ListItem>
                  <ListItem
                    key="dataAnalyticsListItem"
                    disablePadding
                    sx={{ marginBottom: 2 }}
                  >
                    <Link href="/services/data-analytics/">Data Analytics</Link>
                  </ListItem>
                  <ListItem
                    key="dataEngineeringListItem"
                    disablePadding
                    sx={{ marginBottom: 2 }}
                  >
                    <Link href="/services/data-engineering/">
                      Data Engineering
                    </Link>
                  </ListItem>
                  <ListItem
                    key="appDevListItem"
                    disablePadding
                    sx={{ marginBottom: 2 }}
                  >
                    <Link href="/services/cloud-native-app-development/">
                      Cloud Native App Development
                    </Link>
                  </ListItem>
                  <ListItem
                    key="devSecOpsListItem"
                    disablePadding
                    sx={{ marginBottom: 2 }}
                  >
                    <Link href="/services/devsecops/">DevSecOps</Link>
                  </ListItem>
                  <ListItem
                    key="costEngListItem"
                    disablePadding
                    sx={{ marginBottom: 2 }}
                  >
                    <Link href="/services/cost-engineering/">
                      Cost Engineering
                    </Link>
                  </ListItem>
                </List>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        <ListItem key="About Us" disablePadding>
          <ListItemButton sx={{ textAlign: "left" }}>
            <Link href="/about-us/">
              <ListItemText primary="About Us" />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem key="Insights" disablePadding>
          <ListItemButton sx={{ textAlign: "left" }}>
            <Link
              onClick={() => {
                window.open("https://medium.com/@manateelabsllc", "_blank");
              }}
            >
              <ListItemText primary="Insights" />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem onClick={handleDrawerToggle} key="Contact" disablePadding>
          <ListItemButton sx={{ textAlign: "left" }}>
            <Link href="#contact">
              <ListItemText primary="Contact" />
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
            >
              <Link href="/">
                <ComputerSVG className="navbar-brand-div" />
              </Link>
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Button
                key="ServicesButton"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Services
              </Button>
              {/* Services  drop down menu */}
              <Menu
                id="services-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Link href="/services/architecture-consulting/">
                    {" "}
                    Architecture Consulting
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link href="/services/data-analytics/">Data Analytics</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link href="/services/data-engineering/">
                    Data Engineering
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link href="/services/cloud-native-app-development/">
                    Cloud Native App Development
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link href="/services/devsecops/">DevSecOps</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link href="/services/cost-engineering/">
                    Cost Engineering
                  </Link>
                </MenuItem>
              </Menu>
              <Button key="AboutButton" href="/about-us/">
                About Us
              </Button>
              <Button
                key="InsightsButton"
                onClick={() => {
                  window.open("https://medium.com/@manateelabsllc", "_blank");
                }}
              >
                Insights
              </Button>
              <Button key="ContactButton" href="#contact">
                Contact
              </Button>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
