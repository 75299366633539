export const Value = () => {
  return (
    <div id="value">
      <div className="container">
        <div className="row">
          <div className="col-xs-5">
            <h2 className="value-header">
              Revolutionize your business through new insights from big data
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-5  col-md-offset-7">
            <h2 className="value-header">
              Increase your feature velocity with DevSecOps solutions
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-5">
            <h2 className="value-header">
              Enhance the reliability of your products with serverless
              infrastructure
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-5  col-md-offset-7">
            <h2 className="value-header">
              Optimize costs through tailor made, budget conscious architecture
              recommendations
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
