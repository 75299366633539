/**
 * Defines the bottom layout which is used across all pages.
 * This includes contact us and the footer.
 */
import { Footer } from "./footer";
import { useState, useEffect } from "react";
import { ContactDataFormat, Contact } from "./contact";
import LayoutBottomData from "../data/layoutBottomData.json";

interface LayoutBottomDataFormat {
  Contact?: ContactDataFormat;
}

export const LayoutBottom = () => {
  const [layoutBottomPageData, setLayoutBottomPageData] =
    useState<LayoutBottomDataFormat>({});
  useEffect(() => {
    setLayoutBottomPageData(LayoutBottomData);
  }, []);
  return (
    <div>
      <Contact data={layoutBottomPageData.Contact} />
      <Footer />
    </div>
  );
};
