/**
 * Creates the About Us page.
 */
import JsonData from "../../data/aboutData.json";
import { useState, useEffect } from "react";
import { MissionValue, Mission } from "./mission";
import { CertificatesView } from "../certificates/certificatesView";

export interface AboutSectionDataFormat {
  paragraph: string;
  Why: string[];
  Why2: string[];
}

export interface AboutDataFormat {
  About?: AboutSectionDataFormat;
  MissionValues?: MissionValue[];
}

export const About = () => {
  const [aboutPageData, setAboutPageData] = useState<AboutDataFormat>({});
  useEffect(() => {
    setAboutPageData(JsonData);
  }, []);
  return (
    <div>
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src="../img/about.jpg"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>
                  {aboutPageData.About
                    ? aboutPageData.About.paragraph
                    : "loading..."}
                </p>
                <h3>Our Values</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {aboutPageData.About
                        ? aboutPageData.About.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {aboutPageData.About
                        ? aboutPageData.About.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Mission data={aboutPageData.MissionValues} />
      <CertificatesView />
    </div>
  );
};
